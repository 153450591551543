<template>
  <div class="jyh-home">
    <home-banner></home-banner>
    <home-panel></home-panel>
    <y-footer></y-footer>
  </div>
</template>

<script>
import HomeBanner from '../components/home/HomeBanner.vue'
import HomePanel from '../components/home/HomePanel.vue'
import YFooter from '../components/Footer.vue'
export default {
  components: { HomeBanner, HomePanel, YFooter }
}
</script>

<style lang="css">
</style>
