<template>
  <div class="jyh-home-babber-container">
    <div class="jyh-home-banner" :style="{zIndex: level1, transform: `translateX(${offset1})`}">
      <div class="view-padding">
        <div class="home-banner-content">
          <div class="line-1">
            智能化云样本库管理平台
          </div>
          <div class="line-2">
            <a>成熟领先</a>
            <a>方便快捷</a>
            <a>提高效率</a>
          </div>
          <div class="line-3">
            <a target="_blank" href="http://biobank.iyxy.cn">进入平台</a>
          </div>
        </div>
      </div>
    </div>
    <div class="jyh-home-banner-2" :style="{zIndex: level2, transform: `translateX(${offset2})`}">
      <div :class="{'jyh-home-banner-inner-2': mounted}">
        <div class="view-padding">
          <div class="home-banner-content">
            <div class="line-1">
              智能化云样本库管理平台
            </div>
            <div class="line-2">
              <a>成熟领先</a>
              <a>方便快捷</a>
              <a>提高效率</a>
            </div>
            <div class="line-3">
              <a target="_blank" href="http://biobank.iyxy.cn">进入平台</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="slots">
      <a :class="{active: activeSlot===1}"></a>
      <a :class="{active: activeSlot===2}"></a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mounted: false,
      timer: null,
      offset1: '0px',
      offset2: '100%',
      level1: 0,
      level2: 0,
      activeSlot: 1
    }
  },
  mounted () {
    this.mounted = true
    this.updateOffset()
  },
  methods: {
    updateOffset () {
      this.timer = setInterval(() => {
        if (this.offset1 === '0px') {
          this.level2 = 0
          this.$nextTick(() => {
            this.offset1 = '-100%'
          })
          this.$nextTick(() => {
            this.offset2 = '0px'
          })
          this.activeSlot = 2
          setTimeout(() => {
            this.level1 = -1
          }, 0)
          setTimeout(() => {
            this.offset1 = '100%'
          }, 1000)
        } else if (this.offset2 === '0px') {
          this.level1 = 0
          this.$nextTick(() => {
            this.offset2 = '-100%'
          })
          this.$nextTick(() => {
            this.offset1 = '0px'
          })
          this.activeSlot = 1
          setTimeout(() => {
            this.level2 = -1
          }, 0)
          setTimeout(() => {
            this.offset2 = '100%'
          }, 1000)
        }
      }, 5000)
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style lang="stylus">
.jyh-home-babber-container
  transition all 0.3s
  position relative
  width 200%
  height 400px

.jyh-home-banner
  position absolute
  background #f2f5f6 url('../../assets/images/bg-01.png') no-repeat
  background-size cover
  height 400px
  width 50%
  transition all 1s

.jyh-home-banner-2
  position absolute
  background #f2f5f6 url('../../assets/images/bg-02-small.png') no-repeat
  background-size cover
  height 400px
  width 50%
  transition all 1s

.jyh-home-banner-inner-2
  position absolute
  background url('../../assets/images/bg-02.png') no-repeat
  background-size cover
  height 400px
  width 100%
  transition all 1s

.home-banner-content
  padding 155px 0 40px 20px
  cursor default
  .line-1
    font-size 32px
    font-weight 400
    color #fff
    letter-spacing 2px
    margin-bottom 5px
  .line-2
    font-size 18px
    font-weight 400
    a
      color rgb(161, 206, 241)
      padding-right 20px
  .line-3
    display inline-block
    margin-top 30px
    padding 3px 30px
    border 1px solid #fff
    a
      color #fff
    &:hover
      background #fff
      a
        color #323232

.slots
  position absolute
  height 12px
  line-height 12px
  left 24%
  bottom 15px
  a
    display inline-block
    width 8px
    height 8px
    margin 0 5px
    border-radius 50%
    border 1px solid rgb(151, 200, 235)
    &.active
      background-color rgb(151, 200, 235)
</style>
