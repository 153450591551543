<template>
  <div class="jyh-home-panel view-padding">
    <div class="home-panel-item">
      <a target="_blank" href="http://www.iyxy.cn/workshop/index.html">
        <div class="circle-icon link-1"><span class="icon"></span></div>
      </a>
      <div class="title">
        科研培训
      </div>
      <div class="desc">
        <p>临床医师如何走出科研困境？怎样才能快速入门科研，有效解除职业发展障碍？</p>
      </div>
    </div>

    <div class="home-panel-item">
      <div class="circle-icon link-2"><span class="icon"></span></div>
      <div class="title">
        医学美图
      </div>
      <div class="desc">
        <p>临床医师如何走出科研困境？怎样才能快速入门科研，有效解除职业发展障碍？</p>
      </div>
    </div>

    <div class="home-panel-item">
      <a target="_blank" href="http://www.iyxy.cn">
        <div class="circle-icon  link-3"><span class="icon"></span></div>
      </a>
      <div class="title">
        科研热点
      </div>
      <div class="desc">
        <p>用最短的时间和最高效的方法全面阐释科研实践，让广大临床医生尽快找到走出科研困境的策略，让科研成为临床工作的一种方式</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="stylus">
.jyh-home-panel
  position: relative
  padding-top: 40px
  padding-bottom: 40px
  background: #fff
  &:after
    content: "."
    visibility: hidden
    display: block
    height: 0
    clear: both

.home-panel-item
  width: 29.33%
  padding-left: 2%;
  padding-right: 2%;
  float: left
  margin: 0 auto
  text-align: center
  color: #555
  .circle-icon
    margin: 0 auto
    width: 140px
    height: 140px
    &.link-1
      border 3px solid #65afdc
      border-radius 50%
      .icon
        display inline-block
        margin 20px
        width 100px
        height 100px
        background url('../../assets/images/educate.svg') no-repeat
        background-size 100% 100%
        transition all .2s linear
      &:hover
        background-color #65afdc
    &.link-2
      border 3px solid rgb(237, 123, 124)
      border-radius 50%
      .icon
        display inline-block
        margin 20px
        width 100px
        height 100px
        background url('../../assets/images/graphic.svg') no-repeat
        background-size 100% 100%
        transition all .2s linear
    &.link-3
      border 3px solid rgb(78, 178, 124)
      border-radius 50%
      .icon
        display inline-block
        margin 20px
        width 100px
        height 100px
        background url('../../assets/images/science.svg') no-repeat
        background-size 100% 100%
      &:hover
        background-color rgb(78, 178, 124)
        /*background-color rgb(86, 196, 136)*/

  .title
    font-size: 22px
    letter-spacing 1px
    margin-top 25px
    margin-bottom 10px
  .desc
    font-size: 13px
    p
      margin: 0
      padding: 0
</style>
